import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CununiaReligioasaPage = () => (
  <Layout>
    <SEO title="Cununia Religiosă" />
    <div className="drawer article-content">
      <h1 className="article-title">Cununia Civilă</h1>

      <p>
        După momentul în care ați devenit o familie în fața legii urmează
        momentul în care vă veți uni și în fața lui Dumnezeu. Căsătoria
        religioasă se poate realiza doar după realizarea cununiei civile.
      </p>
      <section>
        Poate considerați că nu este un moment atât de important, însă cu
        siguranță cununia religioasă este punctul cel mai însemnat din întreaga
        nuntă:
        <ul>
          <li>momentul în care vă sunt puse cununiile și verighetele</li>
          <li>momentul în care ți se oferă vin și pișcoturi</li>
          <li>înconjurarea mesei - Isaia danțuiește</li>
        </ul>
      </section>

      {/* <img src="https://images.unsplash.com/photo-1508839370228-5ae14793c2f5?ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80" /> */}

      <h3 className="paragh-title">
        Ce trebuie să știi înainte de cununia religioasă
      </h3>
      <section>
        <ul>
          <li>
            cununia religioasă nu se poate realiza în zilele de post. Respectă
            acest lucru și nu încerca să cauți preoți care oficiază cununii și
            în aceste zile, este important să respectați acest lucru și să
            pășiți în viața de familie cum se cuvine.
          </li>
          <li>mirii ar trebui să fie botezați în religia ortodoxă</li>
          <li>nașii trebuie să fie cununați ortodox</li>
          <li>
            unii preoți te sfătuiesc să te spovedești și împărtășești înainte de
            cununie. Te poți împărtăși la preotul care vă va cununa sau la
            duhovnicul tău, însă cel mai bine este să discuți acest aspect cu
            preotul care vă va cununa
          </li>
          <li>
            va trebui să prezinți certificatul de la cununia religioasă și va
            trebui să aduci vinul și pișcoturile, probabil în funcție de zonă
            vor trebui aduse și alte lucruri
          </li>
          <li>
            va trebui să mergi la biserică din timp și să verifici dacă data
            aleasă de voi este disponibilă, la fel și ora și tot atunci poți
            întreba și cât dureaza slujba și care este costul taxei de cununie,
            la unele Biserici se plătește înainte, la altele se plătește în ziua
            nunții, la altele nu există nicio taxă și fiecare plătește dacă și
            cât consideră
          </li>
          <li>
            nu uita verighetele și lumânările acasă. Lumânările se vor ține de
            doi tineri necăsătoriți, însă într-unele zone din țară le țin nașii
          </li>
          <li>
            în biserică ar fi indicat să porți voal. După ce te vei căsători,
            preotul îți va recomanda să ai capul acoperit cu batic sau eșsarfă
            în timpul slujbelor
          </li>
          <li>
            Biserica Ortodoxă nu acceptă căsătoriile în exteriorul bisericilor.
            Există proceduri prin care poți cere derogări, mai ales dacă în
            locul unde îți dorești ceremonia religioasă există și un altar
            sfințit, însă va trebui să ai un motiv bine întemeiat.
          </li>
        </ul>
      </section>

      <p>Nu uita servețele, cu siguranță majoritatea oamenilor vor lăcrima deoarece este un moment extrem de emoționant și când te vei afla acolo vei conștientiza cel mai bine că deveniți o familie.</p>

      <div className="nav-section">
        <Link to="/rochia">◀ Rochia de mireasă </Link>
        <Link to="/bauturaCocktail">▶ Băutura la nuntă și Cocktail bar</Link>
      </div>
    </div>
  </Layout>
)

export default CununiaReligioasaPage
